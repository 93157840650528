import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NewsFeed from './NewsFeed';
import Privacy from './Privacy';
import Terms from './Terms';

function App() {
  return (
    <Router>
      <div className="app">
        <main>
          <Routes>
            <Route path="" element={<NewsFeed />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
