import React from 'react';

const Terms = () => {
  return (
    <div className="page">
      <h2>Terms and Conditions</h2>

      <p>Welcome to Newskylab! These Terms and Conditions ("Terms") govern your use of our website and services. By accessing or using our website, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, please do not use our website.</p>

      <h3>1. Use of Our Website</h3>
      <p>You may use our website for lawful purposes only. You agree not to use our website in any manner that could damage, disable, overburden, or impair the site or interfere with any other party’s use of the website. You also agree not to engage in any conduct that could damage the reputation of Newskylab.</p>

      <h3>2. User Accounts</h3>
      <p>To access certain features of our website, you may be required to create an account. You agree to provide accurate, complete, and up-to-date information when creating an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>

      <h3>3. Content Ownership</h3>
      <p>All content on our website, including articles, images, videos, and other media, is the property of Newskylab or our licensors and is protected by copyright, trademark, and other intellectual property laws. You may not copy, reproduce, distribute, or otherwise use any content from our website without our permission, except as allowed under fair use.</p>

      <h3>4. User-Generated Content</h3>
      <p>You may have the ability to submit content to our website, such as comments or feedback. By submitting content, you grant Newskylab a perpetual, irrevocable, royalty-free license to use, display, modify, and distribute your content for any purpose. You also agree that your content will comply with our content guidelines and not infringe upon the rights of third parties.</p>

      <h3>5. Prohibited Activities</h3>
      <p>You agree not to engage in any of the following activities while using our website:</p>
      <ul>
        <li>Uploading or distributing malicious software (e.g., viruses, worms, etc.).</li>
        <li>Impersonating another user or entity.</li>
        <li>Engaging in spam, phishing, or other fraudulent activities.</li>
        <li>Violating the rights of others, including intellectual property rights.</li>
        <li>Engaging in any conduct that disrupts or interferes with the proper functioning of the website.</li>
      </ul>

      <h3>6. Links to Third-Party Websites</h3>
      <p>Our website may contain links to third-party websites. These links are provided for convenience, and we are not responsible for the content or practices of these external sites. You access third-party websites at your own risk.</p>

      <h3>7. Disclaimer of Warranties</h3>
      <p>Newskylab makes no representations or warranties about the accuracy, reliability, or completeness of the content on our website. Our website is provided "as is" and "as available," without any warranties of any kind, either express or implied, including but not limited to the implied warranties of merchantability or fitness for a particular purpose.</p>

      <h3>8. Limitation of Liability</h3>
      <p>In no event shall Newskylab, its affiliates, or its partners be liable for any direct, indirect, incidental, special, or consequential damages arising from your use of our website, including but not limited to damages for loss of profits, goodwill, data, or other intangible losses, even if we have been advised of the possibility of such damages.</p>

      <h3>9. Indemnification</h3>
      <p>You agree to indemnify and hold Newskylab, its affiliates, and its partners harmless from any claims, damages, liabilities, or expenses arising out of your use of our website, your violation of these Terms, or your infringement of the rights of any third party.</p>

      <h3>10. Termination</h3>
      <p>We reserve the right to terminate or suspend your access to our website at our sole discretion, without notice, if you violate these Terms or engage in any conduct that we deem harmful to the website or other users.</p>

      <h3>11. Governing Law</h3>
      <p>These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which Newskylab operates, without regard to its conflict of law principles. Any disputes arising under these Terms shall be resolved in the competent courts of that jurisdiction.</p>

      <h3>12. Changes to These Terms</h3>
      <p>We may update these Terms from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of the website after the effective date of any changes constitutes your acceptance of the revised Terms.</p>

      <p className='copy-right'>© 2024 Newskylab. All rights reserved.</p>
      
    </div>
  );
};

export default Terms;
