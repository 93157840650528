import React from 'react';

const Privacy = () => {
  return (
    <div className="page">
      <h2>Privacy Policy</h2>

      <p>At Newskylab, we value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your data when you use our website and services.</p>

      <h3>1. Information We Collect</h3>
      <p>We collect personal information that you provide to us directly when you use our website, sign up for our newsletter, or engage with our content. This may include:</p>
      <ul>
        <li><strong>Personal Identification Information:</strong> Name, email address, phone number, etc.</li>
        <li><strong>Usage Data:</strong> Information about how you use our website, including your browsing habits, IP address, browser type, and device information.</li>
        <li><strong>Cookies and Tracking Technologies:</strong> We use cookies to enhance user experience, analyze website traffic, and deliver personalized content.</li>
      </ul>

      <h3>2. How We Use Your Information</h3>
      <p>We use the information we collect to improve our services and provide you with a better user experience. Specifically, we use your data for the following purposes:</p>
      <ul>
        <li>To send you newsletters, updates, and promotional offers that you have opted into.</li>
        <li>To improve website functionality and personalize content.</li>
        <li>To analyze user trends and performance.</li>
        <li>To respond to inquiries or feedback from users.</li>
      </ul>

      <h3>3. How We Share Your Information</h3>
      <p>We do not sell or rent your personal information to third parties. However, we may share your data in the following circumstances:</p>
      <ul>
        <li><strong>Service Providers:</strong> We may share your information with trusted third-party service providers who help us run our business, such as hosting providers, email marketing platforms, and analytics services.</li>
        <li><strong>Legal Compliance:</strong> We may disclose your information if required by law or to comply with legal processes.</li>
        <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction.</li>
      </ul>

      <h3>4. Cookies and Tracking Technologies</h3>
      <p>We use cookies and similar tracking technologies to improve the functionality of our website, analyze trends, and provide a personalized experience. You can control how cookies are used by adjusting your browser settings, though disabling certain cookies may impact the functionality of our website.</p>

      <h3>5. Data Security</h3>
      <p>We implement reasonable physical, technical, and administrative measures to protect your personal data. However, please be aware that no method of data transmission or storage is 100% secure, and we cannot guarantee the absolute security of your data.</p>

      <h3>6. Your Data Rights</h3>
      <p>You have the right to access, correct, update, or delete your personal data at any time. If you wish to exercise these rights, please contact us at [email address].</p>

      <h3>7. Third-Party Links</h3>
      <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices of these external sites and encourage you to review their privacy policies before providing any personal information.</p>

      <h3>8. Children’s Privacy</h3>
      <p>Our services are not intended for children under the age of 13. We do not knowingly collect personal data from children under 13. If we become aware that we have inadvertently collected personal information from a child under 13, we will take steps to delete that data.</p>

      <h3>9. Changes to This Privacy Policy</h3>
      <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.</p>

      <p className='copy-right'>© 2024 Newskylab. All rights reserved.</p>

    </div>
  );
};

export default Privacy;
